.bug-report-button {
    position: fixed;
    bottom: 1rem;
    right: 4.25rem;
    height: 2.75rem;
    width: 3.10rem;
    padding: 0.375rem 1rem;
    gap: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    z-index: 1000;

    p {
        margin: 0;
        transition: opacity 0.3s ease;

        &:nth-child(1) {
            opacity: 0;
        }
    }

    &:hover {
        width: unset;

        p:nth-child(1) {
            opacity: 1;
        }
    }
}